define('webapp/components/shopping/shopping-delivery', ['exports', 'webapp/utils/clone-deep', 'webapp/utils/validation-utils', 'webapp/utils/countries'], function (exports, _cloneDeep, _validationUtils, _countries) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    languageService: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    message: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    shoppingCartService: Ember.inject.service(),
    shoppingCart: Ember.computed.alias('shoppingCartService.shoppingCart'),
    countries: Ember.computed(function () {
      return _countries.DEFAULT_COUNTRIES;
    }),

    temporaryEmail: Ember.computed(function () {
      var userProxy = this.get('userProxy');

      if (userProxy.isTemporary) {
        return userProxy.email;
      } else {
        return '';
      }
    }), // for checkout without account

    temporaryEmailValid: Ember.computed('temporaryEmail', function () {
      return (0, _validationUtils.emailValid)(this.get('temporaryEmail'));
    }),

    customerDeliveryAddresses: Ember.computed('userProxy.customer.addresses', function () {
      var customerAddresses = this.get('userProxy.customer.addresses');
      if (customerAddresses) {
        return customerAddresses.filter(function (x) {
          return x.type === 'delivery';
        });
      }
    }),

    refreshShoppingCart: function refreshShoppingCart() {
      if (this.get('shoppingCartService.deliveryAddressValid')) {
        var shoppingCartService = this.get('shoppingCartService');
        shoppingCartService.updateShoppingCart(this.get('shoppingCart'));
      }
    },


    deliveryValid: Ember.computed('shoppingCartService.deliveryValid', 'userProxy.isLoggedIn', 'temporaryEmailValid', function () {
      if (!this.get('userProxy.isLoggedIn') && !this.get('temporaryEmailValid')) {
        return false;
      }

      return this.get('shoppingCartService.deliveryValid');
    }),

    createTemporaryUser: function createTemporaryUser() {
      var _this = this;

      var myStorage = this.get('myStorage');

      var temporaryEmail = this.get('temporaryEmail');

      var userData = {
        email: temporaryEmail,
        userName: temporaryEmail,
        gender: this.get('shoppingCart.invoiceAddress.gender'),
        firstName: this.get('shoppingCart.invoiceAddress.firstName'),
        lastName: this.get('shoppingCart.invoiceAddress.lastName'),
        language: this.get('languageService.selectedLanguage'),
        siteId: this.get('cachedData.cachedSite._id')
      };

      return myStorage.user.ajaxPost({ data: userData, param: 'temporary_register_and_login' }).then(function (user) {
        var userProxy = _this.get('userProxy');
        userProxy._login(user);
      });
    },


    actions: {
      next: function next() {
        var _this2 = this;

        var shoppingCartService = this.get('shoppingCartService');
        var isLoggedIn = this.get('userProxy.isLoggedIn');
        var isNotLoggedIn = !isLoggedIn && !this.get('userProxy.isTemporary');
        var additional = { redirect: false };
        var p = shoppingCartService.updateShoppingCartWODebounce(this.get('shoppingCart'));

        if (isNotLoggedIn) {
          p = this.createTemporaryUser().then(function () {
            return shoppingCartService.transferCartToUser();
          }).then(function () {
            additional = shoppingCartService.getAdditional();
          });
        }

        p.then(function () {
          if (_this2.get('userProxy.customer')) {
            var shoppingCart = _this2.get('shoppingCart');
            var defaultDeliveryAddressType = shoppingCart.defaultDeliveryAddressType;

            if (defaultDeliveryAddressType) {
              var defaultDeliveryAddress = null;

              if (defaultDeliveryAddressType === 'deliveryAddress') {
                defaultDeliveryAddress = (0, _cloneDeep.default)(shoppingCart.deliveryAddress);
              }

              return shoppingCartService.setCustomerDefaultDeliveryAddress(defaultDeliveryAddress);
            }
          }
        });

        p.then(function () {
          if (additional.redirect) {
            var message = _this2.get('message');
            message.infoT('checkout.redirect.' + additional.reason);
            _this2.get('router').transitionTo('checkout.cart');
          } else {
            _this2.get('router').transitionTo('checkout.payment');
          }
        }).catch(function (error) {
          var message = _this2.get('message');
          message.error(error.payload.message);
        });
      },
      defaultAddressTypeChange: function defaultAddressTypeChange(type, e) {
        var checked = e.target.checked;
        var defaultDeliveryAddressType = checked ? type : null;
        this.set('shoppingCart.defaultDeliveryAddressType', defaultDeliveryAddressType);
      },
      expressDeliveryChange: function expressDeliveryChange() {
        this.refreshShoppingCart();
      },
      shippingCountryChange: function shippingCountryChange(countryCode) {
        var shoppingCartService = this.get('shoppingCartService');
        if (!shoppingCartService.isCountryValidForDelivery(countryCode)) {
          this.set('shoppingCart.deliveryDetails.wantExpressDelivery', false);
        }
        this.refreshShoppingCart();
      },
      wantPickupChange: function wantPickupChange() {
        this.refreshShoppingCart();
      },
      updateInvoiceAddress: function updateInvoiceAddress() {
        var address = this.get('userProxy.customer.address');
        var shoppingCartService = this.get('shoppingCartService');

        shoppingCartService.setAddress('invoice', address);
      },
      updateDeliveryAddress: function updateDeliveryAddress(index, address) {
        var userProxy = this.get('userProxy');
        var customer = (0, _cloneDeep.default)(this.get('userProxy.customer'));
        var addresses = [];
        var oldAddresses = this.get('userProxy.customer.addresses');
        var shoppingCartService = this.get('shoppingCartService');

        for (var i = 0; i < oldAddresses.length; i++) {
          var a = (0, _cloneDeep.default)(oldAddresses[i]);
          a.isDefault = i === index;
          addresses.push(a);
        }
        customer.addresses = addresses;
        userProxy.updateCustomer(customer).then(function () {
          var hasSeparateDeliveryAddress = index !== 0;
          shoppingCartService.set('shoppingCart.hasSeparateDeliveryAddress', hasSeparateDeliveryAddress);

          shoppingCartService.setAddress('delivery', address);
        });
      }
    }
  });
});