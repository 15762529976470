define('webapp/locales/de/translations', ['exports', 'webapp/locales/de/translations-base'], function (exports, _translationsBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    'user.ui.accountToolbarLoginTooltip': 'Sie sind nicht angemeldet. Jetzt anmelden.',
    'application.ui.navigateOneLevelUp': "Zurück"

  });
});